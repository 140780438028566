<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    width="60%"
    top="2rem"
    :close-on-click-modal="false"
    class="nsi-energy-object">
    <div slot="title">
      ЛЭП
      <template v-if="userCanEdit">
        <el-button v-if="!editMode" class="back_action_panel--first_element" @click="handleEdit" type="text">
          <i class="el-icon-edit"></i> Редактировать
        </el-button>
        <el-button v-if="editMode" class="back_action_panel--first_element custom-button " @click="handleSave" type="text">
          <SaveIcon /> Сохранить
        </el-button>
        <el-button @click="handleDelete(primaryEquipment.id)" :disabled="primaryEquipment.isAip || primaryEquipment.id == null" type="text" icon="el-icon-delete">
          Удалить
        </el-button>
      </template>
     </div>
    <el-form :rules="rules" v-loading="loading" ref="primaryEquipmentForm" :model="primaryEquipment" label-width="18rem">
      <el-form-item prop="isAip" label="СК-11:">
        <el-checkbox
          v-model="primaryEquipment.isAip"
          disabled>
        </el-checkbox>
      </el-form-item>
      <el-form-item prop="analysisIds" label="Идентификатор ПК &quot;Анализ 2009&quot;:">
        <el-select v-model="primaryEquipment.analysisIds"
                   multiple
                   filterable
                   allow-create
                   default-first-option
                   placeholder=""
                   :disabled="!editMode">
        </el-select>
</el-form-item>
      <el-form-item prop="mRid" label="mRid:">
        <el-input
          v-model="primaryEquipment.mRid"
          :disabled="!editMode || primaryEquipment.isAip">
        </el-input>
      </el-form-item>
      <el-form-item prop="name" label="Наименование:">
        <el-input
          v-model="primaryEquipment.name"
          :disabled="!editMode || primaryEquipment.isAip">
        </el-input>
      </el-form-item>
      <el-form-item prop="energyObjectIds" label="Энергообъекты:">
        <div class="selected-tags">
          <el-tag
            v-for="tag in primaryEquipment.energyObjectIdsInit"
            :key="tag.id"
            size="small"
            class="mr-1 mb-1">
            {{tag.value}}
          </el-tag>
          <el-button v-if="editMode && !primaryEquipment.isAip" type="primary" size="small" @click="handleEnergyObjectsSelect">
            Выбрать
          </el-button>
        </div>
        <!-- <remote-search-input
          v-model="primaryEquipment.energyObjectIds"
          searchUrl="/api/energyObjects/SearchByName"
          return-prop="id"
          size="large"
          multiple
          :allow-create="false"
          :show-when-init="true"
          :initial-value="primaryEquipment.energyObjectIdsInit"
          :start-length=3
          :disabled="!editMode || primaryEquipment.isAip"
        /> -->
      </el-form-item>
      <el-form-item prop="baseVoltageId" label="Класс напряжения:">
        <el-select filterable clearable v-model="primaryEquipment.baseVoltageId" :disabled="!editMode || primaryEquipment.isAip">
          <el-option
            v-for="item in baseVoltages"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="isOAPV" label="Есть ОАПВ:">
        <el-checkbox
          :disabled="!editMode "
          v-model="primaryEquipment.isOAPV">
        </el-checkbox>
      </el-form-item>
      <el-form-item prop="ownerOrganizations" label="Владение:">
        <remote-search-input
            v-model="primaryEquipment.ownerOrganizations"
            searchUrl="/api/organizations/SearchByName"
            size="large"
            return-prop="id"
            :allow-create="false"
            :multiple="true"
            :show-when-init="true"
            :initial-value="primaryEquipment.ownerOrganizationsInit"
            :start-length=3
            :disabled="!editMode || primaryEquipment.isAip"
          >
          </remote-search-input>
      </el-form-item>
      <!-- <el-form-item prop="technicalOrganizations" label="Технический учёт РЗА:">
        <remote-search-input
            v-model="primaryEquipment.technicalOrganizations"
            searchUrl="/api/organizations/SearchByName"
            size="large"
            return-prop="id"
            :allow-create="false"
            :multiple="true"
            :show-when-init="true"
            :initial-value="primaryEquipment.technicalOrganizationsInit"
            :start-length=3
            :disabled="!editMode || primaryEquipment.isAip"
          >
          </remote-search-input>
      </el-form-item> -->
      <el-form-item prop="technologicalAdministrationOrganizations" label="Технологическое управление:">
        <!-- <remote-search-input
            v-model="primaryEquipment.technologicalAdministrationOrganizations"
            searchUrl="/api/organizations/SearchByName"
            size="large"
            return-prop="id"
            :allow-create="false"
            :multiple="true"
            :show-when-init="true"
            :initial-value="primaryEquipment.technologicalAdministrationOrganizationsInit"
            :start-length=3
            :disabled="!editMode || primaryEquipment.isAip"
          >
          </remote-search-input> -->
          <el-select multiple filterable  :disabled="!editMode || primaryEquipment.isAip"
                      clearable v-model="primaryEquipment.technologicalAdministrationOrganizations" size="small"
                      popper-class="search-select">
            <div class="pl-3">
                <el-button type="text" size="small"
                           @click="primaryEquipment.technologicalAdministrationOrganizations = techAdministrationOrganizations.map((item) => item.id)">
                  Выбрать всё
                </el-button>
            </div>
            <el-option
              v-for="item in techAdministrationOrganizations"
              :key="item.id"
              :label="item.value"
              :value="item.id">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item prop="technologicalManagementOrganizations" label="Технологическое ведение:">
        <!-- <remote-search-input
            v-model="primaryEquipment.technologicalManagementOrganizations"
            searchUrl="/api/organizations/SearchByName"
            size="large"
            return-prop="id"
            :allow-create="false"
            :multiple="true"
            :show-when-init="true"
            :initial-value="primaryEquipment.technologicalManagementOrganizationsInit"
            :start-length=3
            :disabled="!editMode || primaryEquipment.isAip"
          >
          </remote-search-input> -->
          <el-select multiple filterable  :disabled="!editMode || primaryEquipment.isAip"
                      clearable v-model="primaryEquipment.technologicalManagementOrganizations" size="small"
                      popper-class="search-select">
            <div class="pl-3">
                <el-button type="text" size="small"
                           @click="primaryEquipment.technologicalManagementOrganizations = techManagementOrganizations.map((item) => item.id)">
                  Выбрать всё
                </el-button>
            </div>
            <el-option
              v-for="item in techManagementOrganizations"
              :key="item.id"
              :label="item.value"
              :value="item.id">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item prop="dispatchAdministrationOrganizations" label="Диспетчерское управление:">
        <!-- <remote-search-input
            v-model="primaryEquipment.dispatchAdministrationOrganizations"
            searchUrl="/api/organizations/SearchByName"
            size="large"
            return-prop="id"
            :allow-create="false"
            :multiple="true"
            :show-when-init="true"
            :initial-value="primaryEquipment.dispatchAdministrationOrganizationsInit"
            :start-length=3
            :disabled="!editMode || primaryEquipment.isAip"
          >
          </remote-search-input> -->
          <el-select multiple filterable :disabled="!editMode || primaryEquipment.isAip"
                      clearable v-model="primaryEquipment.dispatchAdministrationOrganizations" size="small"
                      popper-class="search-select">
            <div class="pl-3">
                <el-button type="text" size="small"
                           @click="primaryEquipment.dispatchAdministrationOrganizations = duOrganizations.map((item) => item.id)">
                  Выбрать всё
                </el-button>
            </div>
            <el-option
              v-for="item in duOrganizations"
              :key="item.id"
              :label="item.value"
              :value="item.id">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item prop="dispatchManagementOrganizations" label="Диспетчерское ведение:">
        <!-- <remote-search-input
            v-model="primaryEquipment.dispatchManagementOrganizations"
            searchUrl="/api/organizations/SearchByName"
            size="large"
            return-prop="id"
            :allow-create="false"
            :multiple="true"
            :show-when-init="true"
            :initial-value="primaryEquipment.dispatchManagementOrganizationsInit"
            :start-length=3
            :disabled="!editMode || primaryEquipment.isAip"
          >
          </remote-search-input> -->
          <el-select multiple filterable :disabled="!editMode || primaryEquipment.isAip"
                      clearable v-model="primaryEquipment.dispatchManagementOrganizations" size="small"
                      popper-class="search-select">
            <div class="pl-3">
                <el-button type="text" size="small"
                           @click="primaryEquipment.dispatchManagementOrganizations = dvOrganizations.map((item) => item.id)">
                  Выбрать всё
                </el-button>
            </div>
            <el-option
              v-for="item in dvOrganizations"
              :key="item.id"
              :label="item.value"
              :value="item.id">
            </el-option>
          </el-select>
      </el-form-item>
    </el-form>
    <energy-objects-modal
      v-if="energyObjectsModalVisible"
      v-model="energyObjectsModalVisible"
      :data="primaryEquipment.energyObjectIdsInit"
      @on-update="energyObjectUpdate"
    />
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import primaryEquipmentsApi from '@/api/nsi/primaryEquipments';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import SaveIcon from '@/assets/icons/save.svg';
import validationRules from '@/mixins/validationRules';
import energyObjectsModal from '@/views/Nsi/Lines/EnergyObjectsSelect/EnergyObjectsModal';
import organizationsApi from '@/api/nsi/organizations';

export default {
  name: 'LineModal',
  props: ['value', 'primaryEquipment'],
  mixins: [validationRules],
  computed: {
    ...mapGetters('dictionaries', ['baseVoltages']),
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.editMode = false;
        this.$emit('input', val);
      },
    },
  },
  components: { RemoteSearchInput, SaveIcon, energyObjectsModal },
  data() {
    return {
      editMode: false,
      loading: false,
      energyObjectsModalVisible: false,
      techAdministrationOrganizations: [],
      techManagementOrganizations: [],
      dvOrganizations: [],
      duOrganizations: [],
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        primaryEquipmentTypeId: [
          {
            type: 'number', required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'
          }
        ],
        energyObjectIds: [
          {
            type: 'array', required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'
          },
          { type: 'array', validator: this.arrayTwoItemsRequired, trigger: 'blur' }
        ],
        analysisIds: [
          { validator: this.isUUIDList, trigger: 'blur' }
        ],
        mRid: [ 
          { validator: this.isUUID, trigger: 'blur' }
        ]
      },
    };
  },
  async mounted() {
    this.$root.$on('addEvent', (x) => {
      this.editMode = x.editMode;
    });
    const techAdmOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Технологическое управление');
    if (techAdmOrgs.data) {
      this.techAdministrationOrganizations = techAdmOrgs.data;
    }
    const techManOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Технологическое ведение');
    if (techManOrgs.data) {
      this.techManagementOrganizations = techManOrgs.data;
    }
    const dvOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Диспетчерское ведение');
    if (dvOrgs.data) {
      this.dvOrganizations = dvOrgs.data;
    }
    const duOrgs = await organizationsApi.getOrganizationSearchByNameAndRole('Диспетчерское управление');
    if (duOrgs.data) {
      this.duOrganizations = duOrgs.data;
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.editMode = false;
    },
    async addPrimaryEquipment(primaryEquipment) {
      await primaryEquipmentsApi.addPrimaryEquipment(primaryEquipment);
      this.$emit('on-create');
    },
    async updatePrimaryEquipment(primaryEquipment) {
      await primaryEquipmentsApi.updatePrimaryEquipment(primaryEquipment);
      this.$emit('on-update');
    },
    async validateForm() {
      const valid = await this.$refs.primaryEquipmentForm.validate();
      return valid;
    },
    async handleSave() {
      // console.log(this.primaryEquipment);
      const valid = await this.validateForm();
      if (!valid) return;

      this.primaryEquipment.primaryEquipmentTypeId = 1; // ЛЭП
      const request = this.primaryEquipment.id ? this.updatePrimaryEquipment : this.addPrimaryEquipment;
      this.loading = true;
      await request(this.primaryEquipment);
      this.dialogVisible = false;
      this.loading = false;
    },
    handleEdit() {
      this.editMode = true;
    },
    handleDelete(id) {
        this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
          confirmButtonText: 'ОК',
          cancelButtonText: 'Отмена',
          type: 'warning',
        }).then(async () => {
          this.loading = true;

          await primaryEquipmentsApi.deletePrimaryEquipment(id);
          this.$emit('on-delete');
          this.dialogVisible = false;
          this.loading = false;
        });
      },
    handleEnergyObjectsSelect() {
      this.energyObjectsModalVisible = true;
    },
    energyObjectUpdate(eo) {
      this.primaryEquipment.energyObjectIdsInit = eo.map((t) => ({ value: t.name, ...t }));
      this.primaryEquipment.energyObjectIds = eo.map((t) => t.id);
    },
  },
};
</script>

<style scoped>

</style>
