export default {
  data() {
    return {
      autoCollapse: false,
    };
  },
  methods: {
    // отвечает за кнопку "Свернуть/развернуть всё" в дереве от elementUI
    elTreeCollapse() {
      // eslint-disable-next-line no-underscore-dangle
      // const nodes = this.$refs.tree.store._getAllNodes();
      // const opened = nodes.some((x) => x.expanded === true);

      // Возможно стоит обрабатывать и внутренние, но я пока не увидел необходимости
      // Даже если оставить старые nodes (подцепляющие все загруженные ранее страницы), с кодом ниже это не так уж долго работает
      const nodes = this.$refs.tree.root.childNodes;

      this.autoCollapse = !this.autoCollapse;
      this.$refs.tree.store.defaultExpandAll = !this.autoCollapse;

      if (this.autoCollapse) {
        nodes.forEach((node) => {
          if (!node.isLeaf) {
            node.expanded = false;
          }
          // node.collapse();
        });
      } else {
        nodes.forEach((node) => {
          if (!node.isLeaf) {
            node.expanded = true;
          }
          // node.expand();
        });
      }
    },
  }
};
