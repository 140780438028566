<template>
    <div>
    <el-row class="mt-2 mb-0">
        <div class="selected-tags">
        <el-tag
          v-for="tag in selectedEnergyObjectsDistinct"
          :key="tag.id"
          :closable="!tag.disabled"
          size="small"
          @close="handleEnergyObjectDelete(tag)"
          class="mr-1 mb-1">
          {{tag.name}}
        </el-tag>
      </div>
    </el-row>
    <el-row class="m-0" :gutter="20">
        <el-col>
        <pagination
            style="text-align: center"
            :total-count="totalCount"
            :page-size.sync="pageSize"
            :current-page.sync="pageNumber"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
        </el-col>
    </el-row>
    <div v-loading="loading">
      <el-container class="tree-container mb-2" :style="{'height' : tableHeight}">
        <el-tree :data="energyObjects"
                  default-expand-all
                  :default-checked-keys="selectedEnergyObjects.map((item) => item.id)"
                  show-checkbox
                  node-key="id"
                  @check-change="handleCheckChange"
                  ref="tree">
          <span class="custom-tree-node" slot-scope="{ node }">
            <div class="tdWraper">
              <span>
                <FactoryIcon />
              </span>
              <span>{{ node.data.name }}</span>
            </div>
          </span>
        </el-tree>
      </el-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import energyObjectsApi from '@/api/nsi/energyObjects';
import pagination from '@/components/Ui/Pagination';
import formatters from '@/mixins/formatters.js';
import tableResize from '@/mixins/tableResize.js';
import FactoryIcon from '@/assets/icons/factory.svg';


export default {
  name: 'EnergyObjectsTable',
  props: {
    filterModel: { type: Object, default: () => {} },
    filterActive: { type: Boolean, default: () => true },
    data: { type: Array, default: () => [] },
  },
  mixins: [formatters, tableResize],
  components: { pagination, FactoryIcon },
  data() {
    return {
      loading: false,
      energyObjects: [],
      pageNumber: 1,
      pageSize: 100,
      modalClass: 'lines-eo-select-modal',
      tableSizeConstant: 150,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      dialogVisible: false,
      selectedEnergyObjects: [],
      selectedEnergyObjectsDistinct: []
    };
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
  },
  watch: {
    filterModel: {
      handler() {
        this.getEnergyObjects();
      },
      deep: true
    },
  },
  created() {
    this.selectedEnergyObjectsDistinct = this.data.map((t) => ({ name: t.value, ...t }));
  },
  async mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.getTableHeight();
    });
    await this.getEnergyObjects();
    this.tableHeight = this.getTableHeight();
  },
  methods: {
    async getEnergyObjects() {
      this.loading = true;
      const params = {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          nameFilter: this.filterModel.nameFilter,
          ownerOrganizationFilter: [],
          dcOrganizationFilter: [],
          isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
          isRuFilter: this.filterModel.isRuFilter,
          sortField: this.sortField,
          sortDesc: this.sortDesc
      };
      const res = await energyObjectsApi.getEnergyObjectsWithPagination(params);
      if (res.data) {
        this.energyObjects = res.data.items;
        this.totalCount = res.data.totalCount;
        // обход дерева
        this.$nextTick(() => {
          const childs = [];
          this.energyObjects.forEach((el) => childs.push(el));
          this.selectedEnergyObjectsDistinct.forEach((energyObject) => {
            const forSelect = childs.filter((i) => i.id === energyObject.id);
            forSelect.forEach((c) => this.$refs.tree.setChecked(c.id, true));
          });
        });
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getEnergyObjects();
    },
    async handleCurrentChange() {
      await this.getEnergyObjects();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortDesc = null;
        this.sortField = null;
      } else {
        this.sortDesc = event.order === 'descending';
        this.sortField = event.prop;
      }

      await this.getEnergyObjects();
    },
    handleCheckChange(data, checked) {
      if (checked) {
        this.$nextTick(() => {
          this.selectedEnergyObjects.push(data);
          if (!this.selectedEnergyObjectsDistinct.some((t) => t.id === data.id)) {
            this.selectedEnergyObjectsDistinct.push({ id: data.id, name: data.name, value: data.name });
          }
          this.selectedEnergyObjectsDistinct.forEach((c) => this.$refs.tree.setChecked(c.id, false));
        });
      } else {
        this.$nextTick(() => {
          this.selectedEnergyObjects = this.selectedEnergyObjects.filter((item) => item.id !== data.id);
          this.selectedEnergyObjectsDistinct = this.selectedEnergyObjectsDistinct.filter((item) => item.id !== data.id);

          this.selectedEnergyObjectsDistinct.forEach((c) => this.$refs.tree.setChecked(c.id, false));
        });
      }

      this.tableHeight = this.getTableHeight();
      this.$emit('on-update', this.selectedEnergyObjectsDistinct);
    },    
    // clearSelectedEnergyObjects() {
    //   this.selectedEnergyObjectsDistinct = [];
    //   this.selectedEnergyObjects = [];
    //   this.$refs.tree.setCheckedKeys(this.selectedEnergyObjects.map((i) => i.id));

    //   this.$emit('on-update', this.selectedEnergyObjectsDistinct);
    // },
    handleEnergyObjectDelete(energyObject) {
      this.$nextTick(() => {
        this.selectedEnergyObjectsDistinct = this.selectedEnergyObjectsDistinct.filter((s) => s.id !== energyObject.id);
        this.selectedEnergyObjects = this.selectedEnergyObjects.filter((s) => s.id !== energyObject.id);
        this.$refs.tree.setCheckedKeys(this.selectedEnergyObjects);
        this.tableHeight = this.getTableHeight();

        this.$emit('on-update', this.selectedEnergyObjectsDistinct);
      });
    }
  }
};
</script>

<style scoped>
.selected-tags {
  max-height: 5vh;
  overflow-x: auto;
}
.tree-container {
  overflow-y: auto;
  max-height: 40vh;
  min-height: 40vh;
  overflow-x: hidden;
}
</style>
